import React from 'react'
import Footer from '../footer/footer'
import InnerNavbar from '../inner-navbar/inner-navbar'
import failed from '../../assets/img/failed.png';

export default function Failure() {
  return (
    <>
     
<div >
       <div>
        <InnerNavbar/>
     
        <div className='main-about container-fluid'>
          <div className='custom-container cnt-success'>

  

<div className='success-txt-aln'>
<img className='img-success-data' src={failed}></img>
<h6>Registration Failed</h6>
<strong className='txt-color-success'>You were Not Registered to the Event</strong>
</div>

       





</div>
        </div>
      
        <Footer/>
    </div>
     </div>
  </>
  )
}
