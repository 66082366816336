import React from 'react';
import Footer from '../footer/footer';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import { useEffect, useState } from "react";
import axios from "axios";

export default function ContactMain() {
    const [items, setItems] = useState([]);

    

    useEffect(() => {
      const getComments = async () => {
    axios.get('https://iiakottayam.com/iia-api/public/api/contacttbs')
    .then(function (response) {
      setItems(response.data.data);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
      };
      getComments();
    },[]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);



      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [message, setMessage] = useState('');

      function submit(){
        axios.post('http://localhost:8000/api/contactforminsert', {
            contactformname: name,
            contactformemail: email,
            contactformphone: phone,
            contactformmsg: message,
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
  return (
    

<>

<div>
      <div>
        <InnerNavbar/>
        <div className='about-banner container-fluid'>
          <div className='banner-bg contact-bg'>
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className='contact-main container-fluid'>
            <div className='custom-container'>
                <div className='address-box'>
{items.map((item, index) => {
   return (
   





                    <div className='row'  key={item.index}>
                        <div className='col-md-4 col-12'>
                            <div className='inner-box'>
                                <h4>Phone</h4>
                                <a href="tel:">{item.contact_phone}</a>
                                <a href="tel:">{item.contact_phone_opt}</a>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='inner-box'>
                                <h4>Mail</h4>
                                <a href="tel:">{item.contact_email}</a>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='inner-box'>
                                <h4>Address</h4>
                                <p>{item.add_line1}</p>
                                <p>{item.add_line2}</p>
                                <p>{item.add_line3}</p>
                            </div>
                        </div>
                    </div>




              
   );
 })}


</div>
                <div className='row'>
                    <div className='col-md-6 col-12'>
                        <div className='form-box'>
                            <h4>Enquire Now</h4>
                            <Form className='row'>
                                <div className='col-md-6 col-12'>
                                    <Form.Control type="text" placeholder="Your name" value={name} onChange={e => setName(e.target.value)}/>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <Form.Control type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className='col-md-12 col-12'>
                                    <Form.Control type="tel" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)}/>
                                </div>
                                {/* <div className='col-md-6 col-12'>
                                    <Form.Control type="text" placeholder="Your name" />
                                </div> */}
                                <div className='col-12'>
                                    <Form.Control type="textarea" placeholder="Message" rows="4" cols="50" value={message} onChange={e => setMessage(e.target.value)}/>
                                </div>
                                <Button onClick={submit} >Submit</Button>
                            </Form>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='location-map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62944.119460596885!2d76.48557309806033!3d9.594624041163053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b062ba16c6b435f%3A0xbe2b02f68f8dd06e!2sKottayam%2C%20Kerala!5e0!3m2!1sen!2sin!4v1667802805671!5m2!1sen!2sin" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
     </div>
</>
  )
}
