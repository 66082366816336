import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import FormImg from '../../assets/img/form-img.jpg';
import { Link } from 'react-router-dom';
import ECMemberOne from '../../assets/img/contactimg.jpg';
// import ECMemberTwo from '../../assets/img/shintu.jpeg';
// import ECMemberThree from '../../assets/img/george.jpg';
// import ECMemberFour from '../../assets/img/archana.jpg';
// import ECMemberFive from '../../assets/img/renish.jpg';
// import ECMemberSix from '../../assets/img/george-bonny.jpg';
// import ECMemberSeven from '../../assets/img/rajwin.jpg';
// import ECMemberEight from '../../assets/img/vivek.jpg';
// import ECMemberNine from '../../assets/img/thomas-jose.jpeg';
// import ECMemberTen from '../../assets/img/sreeraj.jpg';
// import ECMemberEleven from '../../assets/img/shanti.jpg';
// import ECMemberTwleve from '../../assets/img/prabhul.jpg'; 
// import ECMemberThretten from '../../assets/img/jacob-chandy.jpeg'; 
// import ECMemberFourteen from '../../assets/img/clara.jpeg'; 
// import ECMemberFityeen from '../../assets/img/aswin.jpg';

import Footer from '../footer/footer';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';


export default function MembersMain() {

    const [items, setItems] = useState([]);
    const [cmitems, setCmitems] = useState([]);
    const [mitems, setMitems] = useState([]);

    const [chairmandata, Setchairmandata] = useState('chairman');

  

    useEffect(() => {
      const getComments = async () => {
    axios.get('https://iiakottayam.com/iia-api/public/api/listmemspage')
    .then(function (response) {
      setItems(response.data.data[0].executive_commitee);
      console.log(response.data.data[0].executive_commitee);
      setCmitems(response.data.data[0].commitee_members);
      setMitems(response.data.data[0].other_members);
    })
    .catch(function (error) {
      console.log(error);
    });
      };
      getComments();
    },[]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

      useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
}, [ ])

 
  return (

    

    <div>
        <InnerNavbar/>
        <div className='member-banner container-fluid'>
          <div className='banner-bg member-bg'>
            <h1>Members</h1>
          </div>
        </div>
        <div className='members-main container-fluid home-committee '>
            
            <div className='custom-container'>
            <h4>Executive Committee</h4>
                <div className='committee-row'>   




   {items.map((ecitem, index) => {

    return(
        <div className={index == 1? 'member-box chairman':'member-box other-committee-box'} key={index}>
        
                   <img src={ecitem.officebearer_image == '' ? ECMemberOne : "https://iiakottayam.com/iia-api/public/member/"+ecitem.officebearer_image}/>
                   <h6>{ecitem.officebearer_name}</h6>
                   <p>{ecitem.officebearer_designation}</p>
                       
                   </div>
    );
   
 

    
   })}

                    {/* <div className='member-box other-committee-box'>
                    <img src={ECMemberOne}/>
                    <h6>Shobak Thomas</h6>
                    <p>Past Chairman</p>
                        
                    </div>
                    <div className='member-box chairman'>
                    <img src={ECMemberTwo}/>
                    <h6>Shintu G George</h6>
                    <p>Chairman</p>
                    </div>
                    <div className='member-box other-committee-box'>
                    <img src={ECMemberThree}/>
                    <h6>George A Aikkarakunnel</h6>
                    <p>Upcoming Chairman</p>
                    </div>
                    <div className='member-box other-committee-box'>
                    <img src={ECMemberFour}/>
                    <h6>Archana Arjun</h6>
                    <p>Honourable Secretary</p>
                    </div>
                    <div className='member-box other-committee-box'>
                    <img src={ECMemberFive}/>
                    <h6>Renishkumar G</h6>
                    <p>Treasurer</p>
                    </div> */}
                </div>
            </div>
            <div className='custom-container'>
                <h4>Committee Members</h4>
                <div className='committee-row'> 



                {cmitems.map((cmitem, index) => {

return(
    <div className='member-box' key={index}>
    
               <img src={cmitem.officebearer_image == '' ? ECMemberOne : "https://iiakottayam.com/iia-api/public/member/"+cmitem.officebearer_image}/>
               <h6>{cmitem.officebearer_name}</h6>
               <p>{cmitem.officebearer_designation}</p>
                   
               </div>
);




})}


                    {/* <div className='member-box'>
                        <img src={ECMemberNine}/>
                        <h6>Thomas Jose</h6>
                        <p>State Ex. Member</p>
                    </div>                   
                    <div className='member-box'>
                        <img src={ECMemberSix}/>
                        <h6>George Bonny Rajan</h6>
                        <p>Jt. Secretary</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberSeven}/>
                        <h6>Rajwin Chandy</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberEight}/>
                        <h6>Vivek Chandy</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberTen}/>
                        <h6>Sreeraj S</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberEleven}/>
                        <h6>Shanti Rahul</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberTwleve}/>
                        <h6>Prabhul Mathew</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberThretten }/>
                        <h6>Jacob Chandy</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberFourteen}/>
                        <h6>Clara Rose Jose</h6>
                        <p>Ex. Member</p>
                    </div>
                    <div className='member-box'>
                        <img src={ECMemberFityeen}/>
                        <h6>Aswin Ipe Philip</h6>
                        <p>Ex. Member</p>
                    </div> */}
                </div>
            </div>
            <div className='custom-container'>
                <h4>Other Members</h4>
                <div className='row'>
                    <div className='col-md-12 col-12'>
                        <div className='other-members-list'>
                            <ul>

                            {mitems.map((mitem, index) => {

return(
    <div  key={index}>
    
                  <li>{mitem.member_name}</li>
                   
               </div>
);




})}
                                {/* <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li>
                                <li>Ty Ayelloribbin</li>
                                <li>Hugo First</li>
                                <li>Percy Vere</li>
                                <li>Jack Aranda</li>
                                <li>Olive Tree</li>
                                <li>Fran G. Pani</li>
                                <li>John Quil</li> */}
                            </ul>
                        </div>
                    </div>
                    
                   
                </div>
            </div>
            <div className='home-register container-fluid'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-5 col-12 hm-service-first'>
                    <div className='form-img'>
                        <img src={FormImg}/>
                    </div>
                </div>
                <div className='col-md-7 col-12 m-auto hm-service-second'>
                    <div className='form-box'>
                        <h4>Member Registration</h4>
                        <p>If you want to join IIA Kottayam Center, please register.</p>
                        <Link to="/memberregistration">Register Now</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
        <Footer/>
    </div>
  )
}
