import  React ,{ useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function GalleryDetailMain() {


  const [images, Setimages] = useState([]);
  const { state } = useLocation();
  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get('https://iiakottayam.com/iia-api/public/api/gallerysubtbs?data='+state.post_id);
        console.log(response.data.data);
        Setimages(response.data.data)
      } catch (error) {
        console.error(error);
      }
    }

    getUser();
  },[]);

  return (
    <div className='bgcolordata'>
 <ImageGallery items={images} />
 <Link to="/gallery" class="close"></Link>

    </div>
   
  )
}
