import React from 'react';
import EventImg1 from '../../assets/img/event-img1.jpg';
import EventImg2 from '../../assets/img/event-img2.jpg';
import Footer from '../footer/footer';
import InnerNavbar from '../inner-navbar/inner-navbar';
import { Link } from 'react-router-dom';

import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function MainEventsDetails() {
 
  const { state } = useLocation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getComments = async () => {
  axios.get('https://iiakottayam.com/iia-api/public/api/specificeventlist/?eventiddata='+state.post_id)
  .then(function (response) {
    console.log(response.data.data)
    setItems(response.data.data);
  })
  .catch(function (error) {
    console.log(error);
  });
    };
    getComments();
  },[]);





  // useEffect(() => {
    
  //   const getComments = async () => {
  // axios.get('http://localhost:8000/api/specificeventlist/?eventiddata='+state.post_id)
  // .then(function (response) {
  //   setItems(response.data.data);
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  //   };
  //   getComments();
  // },[]);

  var today = new Date().toISOString().slice(0, 10).replace(/-/g, '')
  //console.log(today)
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const navigate = useNavigate();
  const createPost = (myid) => {
    console.log(state.amount)
      navigate('/eventregister',
          {
              state: {
                  post_id: myid,
                  amount: state.amount
              }
          });
      }  


  return (
    
<>
        <InnerNavbar/>

        <div className='events-detail-main container-fluid'>
            <div className='custom-container'>
              <div className='row'>
    {items.map((item, index) => {

       return (
         <>
          <div className="col-md-9 col-12" key={item.index}>
                  <div className='event-content'>
                    <h4>{item.events_title}</h4>
                    
                    <p><div dangerouslySetInnerHTML={{__html: item.events_description}} /></p>
                    
                   <div className='reg-btn'>
                      {Number(today) <= Number(item.events_date.replace(/-/g, '')) ? <button onClick={() => { createPost(item.events_id, ) }}>Register Now</button> : null}
                    </div>
                  </div>
                </div>
                <div className='col-md-3 col-12'>
                  <div className='event-date'>
                    <h6>Date</h6>
                    <h4>{item.events_date.split('-').reverse().join('-')}</h4>
                     
                    <h6>Venue</h6>
                    <h4>{item.events_venue}</h4>
                  </div>
                </div>
          
         {item['events_gallery_main_image'] == "nill" ? null :item['events_gallery_main_image'].map((item1, index) => {
         console.log(item1)
               return (
                 

                  <div className='col-md-6 col-12' key={item1.index}>
                  <div className='event-img'>
                    <img src={"https://iiakottayam.com/iia-api/public/eventgallerymain/"+item1.image}/>
                  </div>
                </div>
                
                
               );
             })}
         </>
       );
     })}
  
        



                
               
              </div>
        </div>
        </div>
        <Footer/>
    </>
  )
}
