import React from 'react';
import { Link } from 'react-router-dom';
import HomeEventImg from '../../assets/img/PMM-445X381.jpg';

export default function HomeEvents() {
  return (
    <div className='home-events'>
        <h4>register now</h4>
        {/* <Link to="/studentregister"><img src={HomeEventImg}/></Link> */}
        <img src={"https://iiakottayam.com/iia-api/public/BannerSub/ijklmnopqrstuvwxyzABCDEFGHIJKLMN.png"}/>
    </div>
  )
}
