import React from 'react';
import Footer from '../footer/footer';
import HomeAbout from '../home-about/home-about';
import HomeCommittee from '../home-committee/home-committee';
import HomeEventsSection from '../home-events-section/home-events-section';
import HomeEvents from '../home-events/home-events';
import HomeLogo from '../home-logo/home-logo';
import HomeMenu from '../home-menu/home-menu';
import HomeRegister from '../home-register/home-register';
import HomeService from '../home-service/home-service';
import HomeSlider from '../home-slider/home-slider';
import { useEffect, useState } from "react";
import axios from "axios";
import { propTypes } from 'react-bootstrap/esm/Image';

export default function Home() {

    const [items, setItems] = useState({about: "nill",
                                        events: "nill",
                                        executive_members: "nill",
                                        offers: "nill",
                                        banner: "nill"});

  

  useEffect(() => {
    const getComments = async () => {
  axios.get('https://iiakottayam.com/iia-api/public/api/homepageslt')
  .then(function (response) {
    setItems(response.data.data[0]);
    console.log(response.data.data[0].about[0]);
  })
  .catch(function (error) {
    console.log(error);
  });
    };
    getComments();
  },[]);



  return (
    <div>
        <div className='home-slider-main container-fluid'>
            <div className='row'>
                <div className='col-md-9 col-12 p-0 home-slider-res'>
                    <HomeSlider banner={!items.banner ? "nill" : items.banner} latestevnt={!items.events ? "nill" : items.events[0]}/>
                </div>
                <div className='col-md-3 col-12 p-0 sl-section-right home-logo-res'>
                    <HomeLogo/>
                    <HomeMenu/>
                    <HomeEvents/>
                </div>
            </div>
        </div>
    {/* <HomeAbout title={"About US"} description={"description"}/>
        <HomeEventsSection date1={"13-09-2022"} img1={"event-img1.jpg"} description1={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu purus dictum"} date2={"23-09-2022"} img2={"event-img2.jpg"} description2={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu purus dictum"} date3={"19-09-2022"} img3={"event-img1.jpg"} description3={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu purus dictum"}/>
        <HomeCommittee name1={"Shobak Thomas"} img1={"shobak.jpeg"} desgination1={"Past Chairman"} name2={"Shintu G George"} img2={"shintu.jpeg"} desgination2={"Chairman"} name3={"George A Aikkarakunnel"} img3={"george.jpg"} desgination3={"Upcoming Chairman"} name4={"Archana Arjun"} img4={"archana.jpg"} desgination4={"Honourable Secretary"} name5={"Renishkumar G"} img5={"renish.jpg"} desgination5={"Treasurer"}/> */}
        {/* <HomeService description={"We create spaces functional & aesteticaly beautiful for the society or individuals which they use their day to day life. We blend of technicality and creativity. An architect brings their technical knowledge, their artistic flair and their design sensibilities, wrapped up in a comprehensive service that brings your vision to life. We will discuss a variety of design ideas with you, including material finishes, organization of spaces, qualities of natural light and views."}/> */}
        {/* <HomeRegister description={"If you want to join IIA Kottayam, please register."} /> */}



        <HomeAbout about={!items.about[0] ? "nill" : items.about[0]}/>
        <HomeEventsSection events = {!items.events ? "nill" : items.events }/>
        <HomeCommittee executive={!items.executive_members ? "nill" : items.executive_members}/>
        {/* <HomeService offer={!items.offers ? "nill" : items.offers}/> */}
        <HomeService description={"We create spaces functional & aesteticaly beautiful for the society or individuals which they use their day to day life. We blend of technicality and creativity. An architect brings their technical knowledge, their artistic flair and their design sensibilities, wrapped up in a comprehensive service that brings your vision to life. We will discuss a variety of design ideas with you, including material finishes, organization of spaces, qualities of natural light and views."}/>
        <HomeRegister description={"If you want to join IIA Kottayam Center, please register."} />
        <Footer/>
    </div>
  )
}
