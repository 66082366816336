import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../footer/footer';
//import FileUploadImg from '../../assets/img/upload.png';
import axios from 'axios';
import { useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useLocation } from "react-router-dom";
// import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react'
import previewimg from '../../assets/img/upload.png';
import toast, { Toaster } from 'react-hot-toast';

export default function MemberRegistation() {

    const [name, setName] = useState();
    const [gndr, setGndr] = useState();
    const [mail, setMail] = useState();
    const [pswd, setPswd] = useState();
    const [iia, setIia] = useState();
    const [coa, setCoa] = useState();
    const [ofcenme, setOfcenme] = useState();
    const [ofcemail, setOfcemail] = useState();
    const [ph1, setPh1] = useState();
    const [ph2, setPh2] = useState();
    const [place, setPlace] = useState();
    const [fl1, setFl1] = useState(0);
    const [fl2, setFl2] = useState(0);
    const [cntr, setCntr] = useState([]);
    const [cntrval, setCntrval] = useState();
    var mem = fl1 === 0 ? previewimg : URL.createObjectURL(fl1);
    var cert = fl2 === 0 ? previewimg : URL.createObjectURL(fl2);

    useEffect(() => {
        // 👇 scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    async function center() {
        try {
            const response = await axios.post("https://iiakottayam.com/iia-api/public/api/centerlist");
            setCntr(response.data.data);             
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        center();
    }, []);

    function submit(e) {
        e.preventDefault();
        if(!name || !iia || !coa || !mail || !ofcenme || !ofcemail || !place
            || !gndr || !ph1 || !pswd || !fl1 || !fl2 || !cntrval || cntrval == "Select Center") {
                toast.error('Please Fill in the Details!')
            } else {
                let formData = new FormData();
                // console.log(fl1);
                const d = new Date();
                var mn = (d.getMonth() + 1).toString();
                var month = mn.length == 2 ? mn : 0+mn;
                var dt = d.getDate().toString();
                var date = dt.length == 2 ? dt : 0+dt;
                var hr = d.getHours().toString();
                var hour = hr.length == 2 ? hr : 0+hr;
                var min = d.getMinutes().toString();
                var minute = min.length == 2 ? min : 0+min;
                formData.append("time",   d.getFullYear() + "-" +  month + "-" + date + "_" + hour + ":" + minute);
                formData.append("center", cntrval);
                formData.append("membername", name);
                formData.append("memberiiano", iia);
                formData.append("membercoano", coa);
                formData.append("membermailid", mail);
                formData.append("memberorganname", ofcenme);
                formData.append("memberorgmailid", ofcemail);
                formData.append("memberplace", place);
                formData.append("membergender", gndr);
                formData.append("memberphoneone", ph1);
                formData.append("memberphonetwo", ph2);
                formData.append("memberpassword", pswd);
                formData.append("memberphoto", fl1);
                formData.append("membercertificate", fl2);
                //console.log(cntrval);
                axios.post('https://iiakottayam.com/iia-api/public/api/membersinsert', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(function (response) {
                        //console.log(response.data.data);
                        //setLiv(response.data.data);
        
                        
                        if(response.status == 200) {
                            toast.success('Registration Successful')
                        } 
        
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Registration Failed!")
                    });
            }


     
        
            e.target.reset();
            setFl1(0);
            setFl2(0)
    }






    const handleOnChangeprofile = (h) => {
        var reader = new FileReader();

        var hmain = h.target.files[0];
//console.log(hmain)
    
    reader.readAsDataURL(h.target.files[0]);

 

    reader.onload = function (e) {
    

        //console.log("sec  : "+e)
    var image = new Image();
    
    image.src = e.target.result;
    
    image.onload = function () {
    var height = this.height;
    var width = this.width;
    
    if (height == 233 || width == 200) {
      //
    //   alert("Uploaded image has valid Height and Width.");
      setFl1(hmain)
      //console.log("helllll")
      return false;
    }
    setFl1(0)
    toast.error("Height and Width must not exceed permitted resolution.")
    return true;
    };
    };
    }



    const handleOnChangecert = (h) => {
        var reader = new FileReader();

        var hmain = h.target.files[0];
//console.log(hmain)
    
    reader.readAsDataURL(h.target.files[0]);

 

    reader.onload = function (e) {
    

        //console.log("sec  : "+e)
    var image = new Image();
    
    image.src = e.target.result;
    
    image.onload = function () {
    var height = this.height;
    var width = this.width;
    
    if (height == 544 || width == 400) {
      //
    //   alert("Uploaded image has valid Height and Width.");
      setFl2(hmain)
      //console.log("helllll")
      return false;
    }
    setFl2(0)
    toast.error("Height and Width must not exceed permitted resolution.")
    return true;
    };
    };
    }

    return (
        
                    <div>
            <InnerNavbar />
            <div className='about-banner container-fluid'>
                <div className='banner-bg'>
                    <h1>Member Registration</h1>
                </div>
            </div>
            <div className='member-registration-main container-fluid'>
                <div className='custom-container'>
                    <div className='form-box'>
                        <Form onSubmit={submit} className='row'>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setName(e.target.value)} type="text" placeholder="Your name" />
                            </div>
                            <div className='col-md-6 col-12'>

                                            <select onChange={e => setCntrval(e.target.value)}>
                                                <option >Select Center</option>
                                                {cntr.map((c) => <option value={c.center_id} > { c.center }</option>)}
                                            </select>
                            </div>
                            <div className='col-md-6 col-12'>

                                            <select onChange={e => setGndr(e.target.value)}>
                                                <option>Select gender</option>
                                                <option value="m">Male</option>
                                                <option value="f">Female</option>
                                                <option value="o">Others</option>
                                            </select>
                            </div>
                            <div  className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setMail(e.target.value)} type="email" placeholder="Email" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setPswd(e.target.value)} type="password" placeholder="Password" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control maxLength={13} onChange={e => setPh1(e.target.value)} type="tel" placeholder="Phone Number One" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control maxLength={13} onChange={e => setPh2(e.target.value)} type="tel" placeholder="Phone Number Two" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setOfcenme(e.target.value)} type="text" placeholder="Organization Name Here" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setOfcemail(e.target.value)} type="email" placeholder="Organization Mail Here" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setPlace(e.target.value)} type="text" placeholder="Your Place" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setCoa(e.target.value)} type="text" placeholder="COA Number" />
                            </div>
                            <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setIia(e.target.value)} type="text" placeholder="IIA Number" />
                            </div>
                            <div className='col-md-6 col-12'>
                                <div className='fileinput f-profile'>
                                                    <Form.Control onChange={handleOnChangeprofile} type="file"  />
                                                    <img className='albumthumbnail' src={mem} />
                                    <p>Upload Profile Image (.jpeg)
                                    <br></br>
                                        <span className='text-danger'>200w x 233h</span>
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6 col-12'>
                                <div className='fileinput'>
                                                    <Form.Control onChange={handleOnChangecert} type="file"  />
                                                    <img className='albumthumbnail ' src={cert} />
                                    <p>Upload your IIA Cerficate (.jpeg)
                                        <br></br>
                                    <span className='text-danger'>400w x 544h</span>
                                        </p>    
                                </div>
                            </div>
                            <div className='col-12'>
                                <Button type="submit" >Submit</Button>
                            </div>
                                       
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
            <Toaster   position="top-right" reverseOrder={false}/>
                    </div>
               
    )
}