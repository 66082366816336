import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';

export default function InnerNavbar() {
  return (
    <Navbar className='inner-navbar navbar-fixed-top' expand="lg">
        <Navbar.Brand to="/">
            <img src={Logo}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/events">Events</Link>
            <Link to="/members">Members</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/contact">Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
}
