import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Footer from '../footer/footer';
import { useEffect } from 'react';


export default function PrivacyPolicy() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
        <InnerNavbar/>
        <div className='member-banner container-fluid'>
          <div className='banner-bg'>
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className='terms-main container-fluid'>
            <div className='custom-container'>
                <p>The Indian Institute of Architects (IIA) considers the privacy of its visitors as its topmost priority. Here the privacy policy of the Institute is disclosed.</p>
                <h4>Logs and information which we gather</h4>
                <p>IP of the User</p>
                <p>Your mail id is you will log in using your registered account</p>
                <p>We never share this information with a 3rd party because protection of our visitors’ information is of great importance for us.</p>
                <h4>How do we use this information?</h4>
                <p>We use this information to improve the services we offer to our clients, to increase our clientele. This information is also used to track the attempts to make any sort of harm to our website/information on website/users information. This information is also used in research work. We track this information using Google analytics tool, Google webmaster tool, Log files, Cookies.</p>
                <h4>User Registration:</h4>
                <p>We have provided the users with an option to register them with us using the query form/registration form/apply online form. We use this information to contact them to confirm the details they have filed in the form.</p>
                <h4>Discloser for third parties</h4>
                <p>We never offer, provide or serve the information of our users to any third party until and unless there is a requirement on legal terms, or on a notice of Supreme Court. We can lend the information to the third parties like Website developers, security consultants etc. who contribute in protecting our website, promoting it and brand building.</p>
                <h4>Notification of Changes in the Privacy Policy</h4>
                <p>We will evaluate our safety measures and Privacy Policy on an intervallic basis, and we may change our policies as suitable. We may also transform or update our Privacy Policy if we add new services or features. If any modifications are made, we will make suitable amendments to this policy and post them at the Site. We support you to review our Privacy Policy on a regular basis.</p>
  
            </div>
        </div>
        <Footer/>
    </div>
  )
}
