import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import MainAboutImg from '../../assets/img/about-img2.jpg';
import Footer from '../footer/footer';
import { useEffect, useState } from "react";
import axios from "axios";

export default function MainAbout() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getComments = async () => {
  axios.get('https://iiakottayam.com/iia-api/public/api/aboutdetails')
  .then(function (response) {
    setItems(response.data.data);
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
    };
    getComments();
  },[]);

 
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
   

<>

<div >
       <div>
        <InnerNavbar/>
        <div className='about-banner container-fluid'>
          <div className='banner-bg'>
            <h1>About Us</h1>
          </div>
        </div>
        <div className='main-about container-fluid'>
          <div className='custom-container'>
{items.map((item, index) => {
   return (
  



            <div className='row' key={item.index}>
              <div className='col-md-7 col-12 m-auto'>
                <div className='about-content'>
                  <h6>About Us</h6>
                  {/* <h4>{item.about_title}</h4> */}

                  <div
                       dangerouslySetInnerHTML={{__html: item.about_content}}/>
                 
                </div>
              </div>
              <div className='col-md-5 col-12'>
                <div className='about-img'>
                  <img src={"https://iiakottayam.com/iia-api/public/about/"+item.about_img}/>
                </div>
              </div>
            </div>



          
   );
 })}

</div>
        </div>
        <div className='main-vision-mission container-fluid'>
          <div className='custom-container'>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='cap-box'>
                  <h1>Architecture is <br></br>the science <br></br>of art</h1>
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <div className='content-box'>
                  <h4>Vision</h4>
                  <p>
                  The vision is to enhance our built environment through design excellence for the betterment of the
society.
                  </p>
                </div>
                <div className='content-box'>
                  <h4>Mission</h4>
                  <p>
                  The mission is to empower the Architect fraternity to be capable of developing, promoting and
sustaining architectural design practice which is socially responsible for the goodwill of our
community.
                  </p>
                </div>
                <div className='content-box'>
                  <h4>Achievements</h4>
                  <p>
                    Instituted Philip Mather Memorial Awards – a design competition for students of Architecture of various Schools in the State held every year, with the patronage of the family of our founding chairman, late Ar. Philip Mathew in 2004.
                  </p>
                  <p>The same year initiated IIA State Awards for Architecture projects – a first of its kind, based on experiential assessment of the project and has become the most prestigious of Architectural Awards. Kottayam Centre has among its members, National and state awardees.</p>
                  <p>Conducted YAF, the Young Architects Festival in 2004.</p>
                  <p>Organizer of Architecture World Exhibition – a bi-annual event that provides a platform for one to one meeting of Architects, material and technology advances in the building industry and the general public.</p>
                  <p>Introduced Samaswas – a mutually beneficiary scheme that would provide succor to the family of incapacitated or deceased Architect members in 2012.</p>
                  <p>IIA Kottayam has also over the years successfully conducted, over and above its regular activities, major events like</p>
                  <p>Hosted IIA State Awards Function in 2016.</p>
                  <p>Now in 2023 preparing to host the IIA Kerala State Convention at Vagamon.</p>
                </div>  
              </div>
            </div>
          </div>
        </div>
        <Footer/>
    </div>
     </div>
</>
  )
}
