import logo from './logo.svg';
import './App.css';
import Home from './components/home/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter,HashRouter,Routes, Route, Link} from 'react-router-dom';
import MainAbout from './components/about/about';
import MainEvents from './components/events/events';
import MainEventsDetails from './components/events-detail/events-detail';
import MembersMain from './components/members/members';
import GalleryMain from './components/gallery/gallery';
import GalleryDetailMain from './components/gallery-detail/gallery-detail';
import ContactMain from './components/contact/contact';
import MemberRegistation from './components/member-registration/member-registration';
import EventRegister from './components/event-register/event-register';
import StudentRegistation from './components/student-register/student-registration';
import TermsAndConditions from './components/terms-and-conditions/terms-and-conditions';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import RefundPolicy from './components/refund-policy/refund-policy';
import Register from './components/event-register/register';
import Success from './components/response/Success';
import Failure from './components/response/Failure';


function App() {


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/about" element={<MainAbout/>} />
          <Route exact path="/events" element={<MainEvents/>}/>
          <Route exact path="/eventsdetail" element={<MainEventsDetails/>}/>
          <Route exact path="/members" element={<MembersMain/>}/>
          <Route exact path="/gallery" element={<GalleryMain/>}/>
          <Route exact path="/gallerydetail" element={<GalleryDetailMain/>}/>
          <Route exact path="/contact" element={<ContactMain/>}/>
          <Route exact path="/memberregistration" element={<MemberRegistation/>}/>
          <Route exact path="/eventregister" element={<EventRegister/>}/>
          <Route exact path="/register" element={<Register/>}/>
          <Route exact path="/studentregister" element={<StudentRegistation/>}/>
          <Route exact path="/termsandconditions" element={<TermsAndConditions/>}/>
          <Route exact path="/privacypolicy" element={<PrivacyPolicy/>}/>
          <Route exact path="/refundpolicy" element={<RefundPolicy/>}/>
          <Route exact path="/success" element={<Success/>}/>
          <Route exact path="/failure" element={<Failure/>}/>
        </Routes>      
      </BrowserRouter>
    </>
  );
}

export default App;
