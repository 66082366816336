import React from 'react';
import { Link } from 'react-router-dom';
import HomeAboutImg from '../../assets/img/about-img.jpg';
import { useEffect } from 'react';


export default function HomeAbout(props) {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

  return (
    <div className='home-about container-fluid'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-7 col-12 m-auto'>
                    <div className='about-content'>
                        {/* <h4>About Us</h4> */}
                        <h4>{props.about == "nill" ? "nill" :props.about.about_title}</h4>
                        {/* <p>
                        Indian Institute of Architects, Kottayam Centre took origin as a sub-centre in 1990 with a handful of Architects and grew to a Centre in 2011 and now has 160+ Architect members, with a blend of experience and youth. Over the years IIA Kottayam has initiated many noteworthy programs such as Philip Mather Memorial Awards, State Awards for Architecture projects, Architecture World Exhibition. We involve in public projects like the ongoing addition and master plan of Kottayam Medical College. We have given our proposals for reorganization of Kottayam Town and concepts for rationalisation of the incomplete Skywalk project at Seematti Circle among many public involvements.
                        </p>
                        <p>
                        Kottayam Centre is a fraternity of experienced and fresh Architects who work together for improving the profession and its practice. We are invested in sustainable ideals and keen to be partners in creating community awareness and involved public and private spaces.
                        </p> */}
                        <p>
                        {props.about == "nill" ? "nill" : <p> <div dangerouslySetInnerHTML={{__html: props.about.about_content}} /> </p>}
                       
                       
                        </p>
                        {/* <p>{props.description}</p> */}
                        <Link to="/about">About Us</Link>
                    </div>
                </div>
                <div className='col-md-5 col-12'>
                    <div className='about-img'>
                        <img src={props.about == "nill" ? null :"https://iiakottayam.com/iia-api/public/about/" + props.about.about_image}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
