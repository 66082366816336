import React from 'react';
import FormImg from '../../assets/img/form-img.jpg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';

export default function HomeRegister(props) {
  return (
    <div className='home-register container-fluid'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-5 col-12 hm-service-first'>
                    <div className='form-img'>
                        <img src={FormImg}/>
                    </div>
                </div>
                <div className='col-md-7 col-12 m-auto hm-service-second'>
                    <div className='form-box'>
                        <h4>Member Registration</h4>
                        <p>{props.description}</p>
                        <Link to="/memberregistration">Register Now</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
