import React from 'react';
import { Link } from 'react-router-dom';
import EventImgOne from '../../assets/img/event-one.jpg';
import EventImgTwo from '../../assets/img/event-two.jpg';
import EventImgThree from '../../assets/img/event-three.jpg';
import { useNavigate } from 'react-router-dom';


export default function HomeEventsSection(props) {

    const navigate = useNavigate();
    const createPost = (myid,amt) => {
        console.log("event id : "+myid)
        navigate('/eventsdetail',
            {
                state: {
                    post_id: myid,
                    amount: amt,
                    post_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                }
            });
        }  
    // console.log(props.events)
  return (
    <div className='home-events-main container-fluid p-0'>
        <div className='events-banner'>
            <h4>our events</h4>
        </div>
        <div className='events-list'>
            <div className='custom-container'>
                <div className='row'>
                    {props.events == "nill" ? null :props.events.map((e) => {
                    
                        return(
                            <div className='col-md-4 col-12' onClick={() => { createPost(e.events_id,e.events_amount) }}>
                            {console.log(e.events_id)}
                                <div className='events-box'>
                                    <img src={"https://iiakottayam.com/iia-api/public/events/"+e.events_image}/>
                                    <h6>{e.events_date.split('-').reverse().join('-')}</h6>
                                    {/* <p>{e.events_description.replaceAll('\</p>','')}</p> */}
                                    <p><div dangerouslySetInnerHTML={{__html: e.events_description}} /></p>
                                </div>
                            
                        </div>
                        )
                        
                //console.log(e.events_image);
                    })}
                    
                    <div className='event-btn'>
                        <Link to='/events'>View More</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
