import React from 'react';
import { Link } from 'react-router-dom';
import HomeServiceImg from '../../assets/img/service-img.jpg';

export default function HomeService(props) {
  return (
    <div className='home-service container-fluid'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-4 col-12 p-0 hm-service-first'>
                    <div className='service-img'>
                        <img src={HomeServiceImg}/>
                    </div>
                </div>
                <div className='col-md-8 col-12 p-0 hm-service-second'>
                    <div className='service-content'>
                        <h4>What we offer</h4>
                        <p>
                         {props.description}
                        </p>
                        <Link href='#'>Read More</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
