import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import EventImg1 from '../../assets/img/event-img1.jpg';
import EventImg2 from '../../assets/img/event-img2.jpg';
import { Link } from 'react-router-dom';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import Footer from '../footer/footer';
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';



export default function MainEvents() {
    const [items, setItems] = useState([]);

  useEffect(() => {
    const getComments = async () => {
  axios.get('https://iiakottayam.com/iia-api/public/api/eventstbs')
  .then(function (response) {
    console.log(response.data.data)
    setItems(response.data.data);
  })
  .catch(function (error) {
    console.log(error);
  });
    };
    getComments();
  },[]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  const navigate = useNavigate();
  const createPost = (myid, amt) => {
      navigate('/eventsdetail',
          {
              state: {
                  post_id: myid,
                  amount: amt,
                  post_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              }
          });
      }  

  return (
    
     <>
     <div>
        <InnerNavbar/>
        <div className='events-banner container-fluid'>
          <div className='banner-bg event-bg'>
            <h1>Events</h1>
          </div>
        </div>
        <div className='main-events container-fluid'>
            <div className='custom-container'>
     {items.map((item, index) => {
 
        return (
          <div key={item.index}>
           
          {index%2==0?
           
           <div className='event-box'>
                    <div className='row'>
                        <div className='col-md-7 col-12'>
                            <div className='event-img'>
                                <img src={'https://iiakottayam.com/iia-api/public/events/'+ item.events_image}/>
                            </div>
                        </div>
                        <div className='col-md-5 col-12 m-auto'>
                            <div className='event-content'>
                                <h6>{item.events_date.split('-').reverse().join('-')}</h6>
                                <h4>{item.events_title}</h4>
                                <p><div
                                  dangerouslySetInnerHTML={{__html: item.events_description}}/>
                                <button onClick={() => { createPost(item.events_id, item.events_amount) }}>View More <HiOutlineArrowNarrowRight/></button></p>
                            </div>
                        </div>
                    </div>
                </div>
           
           : 
           
           
           
           <div className='event-box-two'>
           <div className='row'>
               <div className='col-md-5 col-12 m-auto'>
                   <div className='event-content'>
                       <h6>{item.events_date.split('-').reverse().join('-')}</h6>
                       <h4>{item.events_title}</h4>
                       <p><div
                       dangerouslySetInnerHTML={{__html: item.events_description}}/>
                       
                       <button onClick={() => { createPost(item.events_id) }}><HiOutlineArrowNarrowLeft/> View More </button></p>
                   </div>
               </div>
               <div className='col-md-7 col-12'>
                   <div className='event-img'>
                       <img src={'https://iiakottayam.com/iia-api/public/events/'+ item.events_image}/>
                   </div>
               </div>
           </div>
       </div>
           }
           
                

               

               
          
           {/* {item['events_gallery_image'].map((item1, index) => {
          console.log(item1)
                return (
                  <div key={item1.index}>

                <i>{item1.image}</i>
                  </div>
                );
              })} */}
          </div>
        );
      })}
        </div>
        </div>
        <Footer/>
    </div> 
   </>
  )
}
