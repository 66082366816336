import React from 'react';
import { Link } from 'react-router-dom';
import SliderImgOne from '../../assets/img/slider1.jpg';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


export default function HomeSlider(props) {
  const navigate = useNavigate();
  var today = new Date().toISOString().slice(0, 10).replace(/-/g, '')

console.log(Number(today));
  return (
    <div>
        <div className='home-slider'>
            <img src={props.banner == "nill" ? null :"https://iiakottayam.com/iia-api/public/banner/"+ props.banner[0].bannerimg}/>
            <div className='reg-btn'>    
            {props.latestevnt.events_date ? Number(today) <= Number(props.latestevnt.events_date.replace(/-/g, '')) ? <button onClick={() => {navigate('/eventregister',
          {
            state: {
              post_id: props.latestevnt.events_id,
              amount:  props.latestevnt.events_amount
          }
              // state: {
              //     event: props.latestevnt
              // }
          })}}>Register Now </button> : console.log('out') : null}


              
            </div>
            <div className='social-media-icons'>
              <Link href="#"><FaFacebookF/></Link>
              <Link href="#"><FaInstagram/></Link>
              <Link href="#"><FaLinkedinIn/></Link>
            </div>
        </div>
    </div>
  )
}
