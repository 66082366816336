import React from 'react';
import ECMemberOne from '../../assets/img/shobak.jpeg';
import ECMemberTwo from '../../assets/img/shintu.jpeg';
import ECMemberThree from '../../assets/img/george.jpg';
import ECMemberFour from '../../assets/img/archana.jpg';
import ECMemberFive from '../../assets/img/renish.jpg';
import { useEffect } from 'react';

export default function HomeCommittee(props) {
    
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])

  return (
    <div className='home-committee container-fluid'>
        <div className='custom-container'>
            <h4>Executive Committee</h4>
            <div className='committee-row'>
                {
                    props.executive == "nill" ? null : props.executive.map((ex,index) => {
                        return(
                            <>
                            <div className={index == 1? 'member-box chairman':'member-box other-committee-box'}>
                    <img src={"https://iiakottayam.com/iia-api/public/member/"+ex.officebearer_image}/>
                    <h6>{ex.officebearer_name}</h6>
                    <p>{ex.officebearer_designation}</p>
                </div>
                </>
                        )
                    })
                }
                
    
            </div>
        </div>
    </div>
  )
}
