import React from 'react'
import Footer from '../footer/footer'
import InnerNavbar from '../inner-navbar/inner-navbar'
import success from '../../assets/img/success.png';

export default function Success() {
  return (
  <>
  
<div >
       <div>
        <InnerNavbar/>
     
        <div className='main-about container-fluid'>
          <div className='custom-container cnt-success'>

  

<div className='success-txt-aln'>
<img className='img-success-data' src={success}></img>
<h6>Registration Successful</h6>
<strong className='txt-color-success'>You have Successfully Registered to the Event</strong>
</div>

       





</div>
        </div>
      
        <Footer/>
    </div>
     </div>
  </>
  )
}
