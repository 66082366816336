import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Gallery1 from '../../assets/img/gallery1.jpg';
import Gallery2 from '../../assets/img/gallery2.jpg';
import Gallery3 from '../../assets/img/gallery3.jpg';
import { Link } from 'react-router-dom';
import Footer from '../footer/footer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from "axios";

export default function GalleryMain() {

    const navigate = useNavigate();
    const createPost = (myid) => {
        navigate('/gallerydetail',
            {
                state: {
                    post_id: myid,
                    post_name: 'Lorem Ipsum',
                    post_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                }
            });
        }   
    const [items, setItems] = useState([]);

    useEffect(() => {
      const getComments = async () => {
    axios.get('https://iiakottayam.com/iia-api/public/api/gallerymaintbs')
    .then(function (response) {
      setItems(response.data);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
      };
      getComments();
    },[]);


    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  
  return (
    
    <>
          <div>
        <InnerNavbar/>
        <div className='about-banner container-fluid'>
          <div className='banner-bg gallery-bg'>
            <h1>Gallery</h1>
          </div>
        </div>
        <div className='gallery-main container-fluid'>
            <div className='custom-container'>
                <div className='row'>
    {items.map((item, index) => {
       return (
         
     
                    <div className='col-md-4 col-12' key={item.index}>
                        <div className='gallery-album-box'onClick={() => { createPost(item.gallery_main_id) }}>
                            {/* <Link to="/gallerydetail"> */}
                                <div className='inner-box'>
                                    <img src={"https://iiakottayam.com/iia-api/public/gallerymain/"+item.gallery_main_image}/>
                                    <h4>{item.gallery_main_name}</h4>
                                </div>
                            {/* </Link> */}
                        </div>
                    </div>
                   
        );
     })}
          </div>
        </div>
        <Footer/>
    </div>
         </div>
    </>
  )
}
