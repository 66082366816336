import React from 'react';
import Logo from '../../assets/img/logo.png';
import LogoText from '../../assets/img/logo-text.png';
import { Link } from 'react-router-dom';
import { Button } from 'bootstrap';

export default function HomeLogo() {
  return (
    <div className='home-logo'>
        <h5 className='logo-h'>The Architects Group Kottayam</h5>
        <img src={Logo}/>
        {/* <img className='text-logo' src={LogoText}/> */}
        {/* <h4>The Indian Institute Of Architects</h4>
        <h4>Kottayam Centre</h4> */}
        <h5 className='logo-b'>THE INDIAN INSTITUTE OF ARCHITECTS <br></br> KOTTAYAM CENTRE</h5>
    </div>
  )
}
