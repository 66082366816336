import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../footer/footer';
//import FileUploadImg from '../../assets/img/upload.png';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useLocation } from "react-router-dom";
// import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react'
// import previewimg from '../../../assets/main/epty.png';

export default function StudentRegistation() {

    const [name, setName] = useState();
    const [clgnme, setClgnme] = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState();
    const [sem, setSem] = useState();
    const [address, setAddress] = useState();
    const loc = useLocation();

    useEffect(() => {
        // 👇 scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    function submit(e) {

        let formData = new FormData();

        formData.append("name", name);
        formData.append("clgnme", clgnme);
        formData.append("phone", phone);
        formData.append("mail", mail);
        formData.append("sem", sem);
        formData.append("address", address);
        formData.append("evntid", loc.event_id);
        

        axios.post('http://localhost:8000/api/studentsinsert', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                /*setName('');
                setClgnme('');
                setMail('');
                setPhone('');
                setSem('');
                setAddress('');*/
            })
            .catch(function (error) {
                console.log(error);
            });
        e.preventDefault();
        e.target.reset();
    }

    const handleSubmit = (event) => {
        
    };


    return (
      
                    <div>
                        <InnerNavbar />
                        <div className='about-banner container-fluid'>
                            <div className='banner-bg'>
                                <h1>Student Registration</h1>
                            </div>
                        </div>
                        <div className='member-registration-main container-fluid'>
                            <div className='custom-container'>
                                <div className='form-box'>
                                    <Form onSubmit={submit} className='row'>
                                        <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setName(e.target.value)} type="text" placeholder="Your name" />
                                        </div>                                        
                                        <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setMail(e.target.value)} type="email" placeholder="Email" />
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setClgnme(e.target.value)} type="text" placeholder="Collage Name" />
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setPhone(e.target.value)} type="text" placeholder="Phone Number" />
                                        </div>                                        
                                        <div className='col-md-6 col-12'>
                                            <Form.Control onChange={e => setAddress(e.target.value)} as="textarea" rows={4} placeholder="Address" />
                                        </div>     
                                        <div className='col-md-6 col-12'>
                                            <div className='row'>                                            
                                                <div className='col-12'>
                                                    <Form.Control onChange={e => setSem(e.target.value)} type="text" placeholder="Your Current Semester" />
                                                </div>
                                               
                                            </div>                                                                                
                                        </div>   
                                        <div className='text-center text-md-end col-12'>
                                                    <Button type="submit" >Submit</Button>
                                                </div>                                                                             
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                
    )
}